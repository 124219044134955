
    <template>
      <section class="content element-doc">
        <h2>Dropdown 下拉菜单</h2>
<p>将动作或菜单折叠到下拉菜单中。</p>
<h3>基础用法</h3>
<p>移动到下拉菜单上，展开更多操作。</p>
<demo-block>
        <div><p>通过组件<code>slot</code>来设置下拉触发的元素以及需要通过具名<code>slot</code>为<code>dropdown</code> 来设置下拉菜单。默认情况下，下拉按钮只要<code>hover</code>即可，无需点击也会显示下拉菜单。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-dropdown&gt;
  &lt;span class=&quot;el-dropdown-link&quot;&gt;
    下拉菜单&lt;i class=&quot;el-icon-arrow-down el-icon--right&quot;&gt;&lt;/i&gt;
  &lt;/span&gt;
  &lt;el-dropdown-menu slot=&quot;dropdown&quot;&gt;
    &lt;el-dropdown-item&gt;黄金糕&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;狮子头&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;螺蛳粉&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item disabled&gt;双皮奶&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item divided&gt;蚵仔煎&lt;/el-dropdown-item&gt;
  &lt;/el-dropdown-menu&gt;
&lt;/el-dropdown&gt;

&lt;style&gt;
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>触发对象</h3>
<p>可使用按钮触发下拉菜单。</p>
<demo-block>
        <div><p>设置<code>split-button</code>属性来让触发下拉元素呈现为按钮组，左边是功能按钮，右边是触发下拉菜单的按钮，设置为<code>true</code>即可。</p>
</div>
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-dropdown&gt;
  &lt;el-button type=&quot;primary&quot;&gt;
    更多菜单&lt;i class=&quot;el-icon-arrow-down el-icon--right&quot;&gt;&lt;/i&gt;
  &lt;/el-button&gt;
  &lt;el-dropdown-menu slot=&quot;dropdown&quot;&gt;
    &lt;el-dropdown-item&gt;黄金糕&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;狮子头&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;螺蛳粉&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;双皮奶&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;蚵仔煎&lt;/el-dropdown-item&gt;
  &lt;/el-dropdown-menu&gt;
&lt;/el-dropdown&gt;
&lt;el-dropdown split-button type=&quot;primary&quot; @click=&quot;handleClick&quot;&gt;
  更多菜单
  &lt;el-dropdown-menu slot=&quot;dropdown&quot;&gt;
    &lt;el-dropdown-item&gt;黄金糕&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;狮子头&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;螺蛳粉&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;双皮奶&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;蚵仔煎&lt;/el-dropdown-item&gt;
  &lt;/el-dropdown-menu&gt;
&lt;/el-dropdown&gt;

&lt;style&gt;
  .el-dropdown {
    vertical-align: top;
  }
  .el-dropdown + .el-dropdown {
    margin-left: 15px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
&lt;/style&gt;

&lt;script&gt;
  export default {
    setup() {
      function handleClick() {
        alert('button click')
      }

      return { handleClick }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>触发方式</h3>
<p>可以配置 click 激活或者 hover 激活。</p>
<demo-block>
        <div><p>在<code>trigger</code>属性设置为<code>click</code>即可。</p>
</div>
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-row class=&quot;block-col-2&quot;&gt;
  &lt;el-col :span=&quot;12&quot;&gt;
    &lt;span class=&quot;demonstration&quot;&gt;hover 激活&lt;/span&gt;
    &lt;el-dropdown&gt;
      &lt;span class=&quot;el-dropdown-link&quot;&gt;
        下拉菜单&lt;i class=&quot;el-icon-arrow-down el-icon--right&quot;&gt;&lt;/i&gt;
      &lt;/span&gt;
      &lt;el-dropdown-menu slot=&quot;dropdown&quot;&gt;
        &lt;el-dropdown-item icon=&quot;el-icon-plus&quot;&gt;黄金糕&lt;/el-dropdown-item&gt;
        &lt;el-dropdown-item icon=&quot;el-icon-circle-plus&quot;&gt;狮子头&lt;/el-dropdown-item&gt;
        &lt;el-dropdown-item icon=&quot;el-icon-circle-plus-outline&quot;
          &gt;螺蛳粉&lt;/el-dropdown-item
        &gt;
        &lt;el-dropdown-item icon=&quot;el-icon-check&quot;&gt;双皮奶&lt;/el-dropdown-item&gt;
        &lt;el-dropdown-item icon=&quot;el-icon-circle-check&quot;&gt;蚵仔煎&lt;/el-dropdown-item&gt;
      &lt;/el-dropdown-menu&gt;
    &lt;/el-dropdown&gt;
  &lt;/el-col&gt;
  &lt;el-col :span=&quot;12&quot;&gt;
    &lt;span class=&quot;demonstration&quot;&gt;click 激活&lt;/span&gt;
    &lt;el-dropdown trigger=&quot;click&quot;&gt;
      &lt;span class=&quot;el-dropdown-link&quot;&gt;
        下拉菜单&lt;i class=&quot;el-icon-arrow-down el-icon--right&quot;&gt;&lt;/i&gt;
      &lt;/span&gt;
      &lt;el-dropdown-menu slot=&quot;dropdown&quot;&gt;
        &lt;el-dropdown-item icon=&quot;el-icon-plus&quot;&gt;黄金糕&lt;/el-dropdown-item&gt;
        &lt;el-dropdown-item icon=&quot;el-icon-circle-plus&quot;&gt;狮子头&lt;/el-dropdown-item&gt;
        &lt;el-dropdown-item icon=&quot;el-icon-circle-plus-outline&quot;
          &gt;螺蛳粉&lt;/el-dropdown-item
        &gt;
        &lt;el-dropdown-item icon=&quot;el-icon-check&quot;&gt;双皮奶&lt;/el-dropdown-item&gt;
        &lt;el-dropdown-item icon=&quot;el-icon-circle-check&quot;&gt;蚵仔煎&lt;/el-dropdown-item&gt;
      &lt;/el-dropdown-menu&gt;
    &lt;/el-dropdown&gt;
  &lt;/el-col&gt;
&lt;/el-row&gt;

&lt;style&gt;
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>菜单隐藏方式</h3>
<p>可以<code>hide-on-click</code>属性来配置。</p>
<demo-block>
        <div><p>下拉菜单默认在点击菜单项后会被隐藏，将<code>hide-on-click</code>属性默认为<code>false</code>可以关闭此功能。</p>
</div>
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-dropdown :hide-on-click=&quot;false&quot;&gt;
  &lt;span class=&quot;el-dropdown-link&quot;&gt;
    下拉菜单&lt;i class=&quot;el-icon-arrow-down el-icon--right&quot;&gt;&lt;/i&gt;
  &lt;/span&gt;
  &lt;el-dropdown-menu slot=&quot;dropdown&quot;&gt;
    &lt;el-dropdown-item&gt;黄金糕&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;狮子头&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;螺蛳粉&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item disabled&gt;双皮奶&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item divided&gt;蚵仔煎&lt;/el-dropdown-item&gt;
  &lt;/el-dropdown-menu&gt;
&lt;/el-dropdown&gt;

&lt;style&gt;
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
&lt;/style&gt;
</code></pre></template></demo-block><h3>指令事件</h3>
<p>点击菜单项后会触发事件，用户可以通过相应的菜单项 key 进行不同的操作</p>
<demo-block>
        
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-dropdown @command=&quot;handleCommand&quot;&gt;
  &lt;span class=&quot;el-dropdown-link&quot;&gt;
    下拉菜单&lt;i class=&quot;el-icon-arrow-down el-icon--right&quot;&gt;&lt;/i&gt;
  &lt;/span&gt;
  &lt;el-dropdown-menu slot=&quot;dropdown&quot;&gt;
    &lt;el-dropdown-item command=&quot;a&quot;&gt;黄金糕&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item command=&quot;b&quot;&gt;狮子头&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item command=&quot;c&quot;&gt;螺蛳粉&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item command=&quot;d&quot; disabled&gt;双皮奶&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item command=&quot;e&quot; divided&gt;蚵仔煎&lt;/el-dropdown-item&gt;
  &lt;/el-dropdown-menu&gt;
&lt;/el-dropdown&gt;

&lt;style&gt;
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
&lt;/style&gt;

&lt;script&gt;
  import { Message } from 'element3'
  export default {
    setup() {
      function handleCommand(command) {
        Message('click on item ' + command)
      }

      return { handleCommand }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>不同尺寸</h3>
<p>Dropdown 组件提供除了默认值以外的三种尺寸，可以在不同场景下选择合适的尺寸。</p>
<demo-block>
        <div><p>额外的尺寸：<code>medium</code>、<code>small</code>、<code>mini</code>，通过设置<code>size</code>属性来配置它们。</p>
</div>
        <template #source><element-demo5 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-dropdown split-button type=&quot;primary&quot;&gt;
  默认尺寸
  &lt;el-dropdown-menu slot=&quot;dropdown&quot;&gt;
    &lt;el-dropdown-item&gt;黄金糕&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;狮子头&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;螺蛳粉&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;双皮奶&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;蚵仔煎&lt;/el-dropdown-item&gt;
  &lt;/el-dropdown-menu&gt;
&lt;/el-dropdown&gt;

&lt;el-dropdown size=&quot;medium&quot; split-button type=&quot;primary&quot;&gt;
  中等尺寸
  &lt;el-dropdown-menu slot=&quot;dropdown&quot;&gt;
    &lt;el-dropdown-item&gt;黄金糕&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;狮子头&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;螺蛳粉&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;双皮奶&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;蚵仔煎&lt;/el-dropdown-item&gt;
  &lt;/el-dropdown-menu&gt;
&lt;/el-dropdown&gt;

&lt;el-dropdown size=&quot;small&quot; split-button type=&quot;primary&quot;&gt;
  小型尺寸
  &lt;el-dropdown-menu slot=&quot;dropdown&quot;&gt;
    &lt;el-dropdown-item&gt;黄金糕&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;狮子头&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;螺蛳粉&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;双皮奶&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;蚵仔煎&lt;/el-dropdown-item&gt;
  &lt;/el-dropdown-menu&gt;
&lt;/el-dropdown&gt;

&lt;el-dropdown size=&quot;mini&quot; split-button type=&quot;primary&quot;&gt;
  超小尺寸
  &lt;el-dropdown-menu slot=&quot;dropdown&quot;&gt;
    &lt;el-dropdown-item&gt;黄金糕&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;狮子头&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;螺蛳粉&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;双皮奶&lt;/el-dropdown-item&gt;
    &lt;el-dropdown-item&gt;蚵仔煎&lt;/el-dropdown-item&gt;
  &lt;/el-dropdown-menu&gt;
&lt;/el-dropdown&gt;
</code></pre></template></demo-block><h3>Dropdown Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>type</td>
<td>菜单按钮类型，同 Button 组件(只在<code>split-button</code>为 true 的情况下有效)</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>size</td>
<td>菜单尺寸，在<code>split-button</code>为 true 的情况下也对触发按钮生效</td>
<td>string</td>
<td>medium / small / mini</td>
<td>—</td>
</tr>
<tr>
<td>split-button</td>
<td>下拉触发元素呈现为按钮组</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>placement</td>
<td>菜单弹出位置</td>
<td>string</td>
<td>top/top-start/top-end/bottom/bottom-start/bottom-end</td>
<td>bottom-end</td>
</tr>
<tr>
<td>trigger</td>
<td>触发下拉的行为</td>
<td>string</td>
<td>hover, click</td>
<td>hover</td>
</tr>
<tr>
<td>hide-on-click</td>
<td>是否在点击菜单项后隐藏菜单</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>show-timeout</td>
<td>展开下拉菜单的延时（仅在 trigger 为 hover 时有效）</td>
<td>number</td>
<td>—</td>
<td>250</td>
</tr>
<tr>
<td>hide-timeout</td>
<td>收起下拉菜单的延时（仅在 trigger 为 hover 时有效）</td>
<td>number</td>
<td>—</td>
<td>150</td>
</tr>
<tr>
<td>tabindex</td>
<td>Dropdown 组件的 <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex">tabindex</a></td>
<td>number</td>
<td>—</td>
<td>0</td>
</tr>
</tbody>
</table>
<h3>Dropdown Slots</h3>
<table>
<thead>
<tr>
<th>Name</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>—</td>
<td>触发下拉列表显示的元素。 注意： 必须是一个元素或者或者组件</td>
</tr>
<tr>
<td>dropdown</td>
<td>下拉列表，通常是 <code>&lt;el-dropdown-menu&gt;</code> 组件</td>
</tr>
</tbody>
</table>
<h3>Dropdown Events</h3>
<table>
<thead>
<tr>
<th>事件名称</th>
<th>说明</th>
<th>回调参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>click</td>
<td><code>split-button</code> 为 true 时，点击左侧按钮的回调</td>
<td>—</td>
</tr>
<tr>
<td>command</td>
<td>点击菜单项触发的事件回调</td>
<td>dropdown-item 的指令</td>
</tr>
<tr>
<td>visible-change</td>
<td>下拉框出现/隐藏时触发</td>
<td>出现则为 true，隐藏则为 false</td>
</tr>
</tbody>
</table>
<h3>Dropdown Menu Item Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>command</td>
<td>指令</td>
<td>string/number/object</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>disabled</td>
<td>禁用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>divided</td>
<td>显示分割线</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>icon</td>
<td>图标类名</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createVNode: _createVNode, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")

  return (_openBlock(), _createBlock(_component_el_dropdown, null, {
    default: _withCtx(() => [
      _createVNode("span", { class: "el-dropdown-link" }, [
        _createTextVNode(" 下拉菜单"),
        _createVNode("i", { class: "el-icon-arrow-down el-icon--right" })
      ]),
      _createVNode(_component_el_dropdown_menu, { slot: "dropdown" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_dropdown_item, null, {
            default: _withCtx(() => [
              _createTextVNode("黄金糕")
            ]),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, null, {
            default: _withCtx(() => [
              _createTextVNode("狮子头")
            ]),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, null, {
            default: _withCtx(() => [
              _createTextVNode("螺蛳粉")
            ]),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, { disabled: "" }, {
            default: _withCtx(() => [
              _createTextVNode("双皮奶")
            ]),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, { divided: "" }, {
            default: _withCtx(() => [
              _createTextVNode("蚵仔煎")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createVNode: _createVNode, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_dropdown, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, { type: "primary" }, {
          default: _withCtx(() => [
            _createTextVNode(" 更多菜单"),
            _createVNode("i", { class: "el-icon-arrow-down el-icon--right" })
          ]),
          _: 1
        }),
        _createVNode(_component_el_dropdown_menu, { slot: "dropdown" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("黄金糕")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("狮子头")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("螺蛳粉")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("双皮奶")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("蚵仔煎")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_dropdown, {
      "split-button": "",
      type: "primary",
      onClick: _ctx.handleClick
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 更多菜单 "),
        _createVNode(_component_el_dropdown_menu, { slot: "dropdown" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("黄金糕")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("狮子头")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("螺蛳粉")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("双皮奶")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("蚵仔煎")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 64))
}
  
    const democomponentExport = {
    setup() {
      function handleClick() {
        alert('button click')
      }

      return { handleClick }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createVNode: _createVNode, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createBlock(_component_el_row, { class: "block-col-2" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 12 }, {
        default: _withCtx(() => [
          _createVNode("span", { class: "demonstration" }, "hover 激活"),
          _createVNode(_component_el_dropdown, null, {
            default: _withCtx(() => [
              _createVNode("span", { class: "el-dropdown-link" }, [
                _createTextVNode(" 下拉菜单"),
                _createVNode("i", { class: "el-icon-arrow-down el-icon--right" })
              ]),
              _createVNode(_component_el_dropdown_menu, { slot: "dropdown" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_dropdown_item, { icon: "el-icon-plus" }, {
                    default: _withCtx(() => [
                      _createTextVNode("黄金糕")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_dropdown_item, { icon: "el-icon-circle-plus" }, {
                    default: _withCtx(() => [
                      _createTextVNode("狮子头")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_dropdown_item, { icon: "el-icon-circle-plus-outline" }, {
                    default: _withCtx(() => [
                      _createTextVNode("螺蛳粉")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_dropdown_item, { icon: "el-icon-check" }, {
                    default: _withCtx(() => [
                      _createTextVNode("双皮奶")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_dropdown_item, { icon: "el-icon-circle-check" }, {
                    default: _withCtx(() => [
                      _createTextVNode("蚵仔煎")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_col, { span: 12 }, {
        default: _withCtx(() => [
          _createVNode("span", { class: "demonstration" }, "click 激活"),
          _createVNode(_component_el_dropdown, { trigger: "click" }, {
            default: _withCtx(() => [
              _createVNode("span", { class: "el-dropdown-link" }, [
                _createTextVNode(" 下拉菜单"),
                _createVNode("i", { class: "el-icon-arrow-down el-icon--right" })
              ]),
              _createVNode(_component_el_dropdown_menu, { slot: "dropdown" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_dropdown_item, { icon: "el-icon-plus" }, {
                    default: _withCtx(() => [
                      _createTextVNode("黄金糕")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_dropdown_item, { icon: "el-icon-circle-plus" }, {
                    default: _withCtx(() => [
                      _createTextVNode("狮子头")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_dropdown_item, { icon: "el-icon-circle-plus-outline" }, {
                    default: _withCtx(() => [
                      _createTextVNode("螺蛳粉")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_dropdown_item, { icon: "el-icon-check" }, {
                    default: _withCtx(() => [
                      _createTextVNode("双皮奶")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_dropdown_item, { icon: "el-icon-circle-check" }, {
                    default: _withCtx(() => [
                      _createTextVNode("蚵仔煎")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { createVNode: _createVNode, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")

  return (_openBlock(), _createBlock(_component_el_dropdown, { "hide-on-click": false }, {
    default: _withCtx(() => [
      _createVNode("span", { class: "el-dropdown-link" }, [
        _createTextVNode(" 下拉菜单"),
        _createVNode("i", { class: "el-icon-arrow-down el-icon--right" })
      ]),
      _createVNode(_component_el_dropdown_menu, { slot: "dropdown" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_dropdown_item, null, {
            default: _withCtx(() => [
              _createTextVNode("黄金糕")
            ]),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, null, {
            default: _withCtx(() => [
              _createTextVNode("狮子头")
            ]),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, null, {
            default: _withCtx(() => [
              _createTextVNode("螺蛳粉")
            ]),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, { disabled: "" }, {
            default: _withCtx(() => [
              _createTextVNode("双皮奶")
            ]),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, { divided: "" }, {
            default: _withCtx(() => [
              _createTextVNode("蚵仔煎")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { createVNode: _createVNode, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")

  return (_openBlock(), _createBlock(_component_el_dropdown, { onCommand: _ctx.handleCommand }, {
    default: _withCtx(() => [
      _createVNode("span", { class: "el-dropdown-link" }, [
        _createTextVNode(" 下拉菜单"),
        _createVNode("i", { class: "el-icon-arrow-down el-icon--right" })
      ]),
      _createVNode(_component_el_dropdown_menu, { slot: "dropdown" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_dropdown_item, { command: "a" }, {
            default: _withCtx(() => [
              _createTextVNode("黄金糕")
            ]),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, { command: "b" }, {
            default: _withCtx(() => [
              _createTextVNode("狮子头")
            ]),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, { command: "c" }, {
            default: _withCtx(() => [
              _createTextVNode("螺蛳粉")
            ]),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, {
            command: "d",
            disabled: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode("双皮奶")
            ]),
            _: 1
          }),
          _createVNode(_component_el_dropdown_item, {
            command: "e",
            divided: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode("蚵仔煎")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onCommand"]))
}
  
    
            const { Message } = Element3
          
  const democomponentExport = {
    setup() {
      function handleCommand(command) {
        Message('click on item ' + command)
      }

      return { handleCommand }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo5": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")
  const _component_el_dropdown = _resolveComponent("el-dropdown")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_dropdown, {
      "split-button": "",
      type: "primary"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 默认尺寸 "),
        _createVNode(_component_el_dropdown_menu, { slot: "dropdown" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("黄金糕")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("狮子头")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("螺蛳粉")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("双皮奶")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("蚵仔煎")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_dropdown, {
      size: "medium",
      "split-button": "",
      type: "primary"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 中等尺寸 "),
        _createVNode(_component_el_dropdown_menu, { slot: "dropdown" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("黄金糕")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("狮子头")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("螺蛳粉")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("双皮奶")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("蚵仔煎")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_dropdown, {
      size: "small",
      "split-button": "",
      type: "primary"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 小型尺寸 "),
        _createVNode(_component_el_dropdown_menu, { slot: "dropdown" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("黄金糕")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("狮子头")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("螺蛳粉")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("双皮奶")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("蚵仔煎")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_dropdown, {
      size: "mini",
      "split-button": "",
      type: "primary"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 超小尺寸 "),
        _createVNode(_component_el_dropdown_menu, { slot: "dropdown" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("黄金糕")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("狮子头")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("螺蛳粉")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("双皮奶")
              ]),
              _: 1
            }),
            _createVNode(_component_el_dropdown_item, null, {
              default: _withCtx(() => [
                _createTextVNode("蚵仔煎")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  